/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CartOverlay {
    &-FreeShipping {
        display: flex;
        flex-direction: column;
        gap: 7px;
        width: auto;
        max-width: 470px;
        margin-block-end: 12px;

        &-Header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
        }

        &-Label {
            font-size: $font-medium-size;
            line-height: $lh-medium-large;
            font-weight: $fw-light;
            color: $black;
        }

        &-Price {
            font-size: $font-medium-size;
            line-height: $lh-medium-large;
            font-weight: $fw-light;
            color: $black;
            opacity: 0.5;
        }

        &-ProgressBar {
            position: absolute;
            background: $black;
            width: 100%;
            height: 1px;
            opacity: 0.16;

            &-Active {
                position: absolute;
                background: $black;
                width: 0;
                height: 1px;
            }

            &-Container {
                position: relative;
            }
        }
    }

    &-YProductsSliderWrapper {
        margin-block-end: 12px;
    }

    &-GiftItemsHeaderWrapper {
        margin-block-end: 25px;
        padding-inline-end: 60px;

        @include mobile {
            padding-inline-end: 12px;
        }

        @include tablet {
            padding-inline-end: 0px;
        }
    }

    &-GiftItemsHeaderContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0,0,0,0.4);
    }

    &-GiftItemsHeader {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.1025rem;
        padding-block-end: 5px;
        margin: 0;
        letter-spacing: 0.08em;
    }

    &-Additional {
        padding-block-start: 16px;

        @include tablet {
            padding-inline: 42px;
        }

        > * {
            width: unset;

            @include desktop {
                width: 470px;
            }
        }

        .CartOverlay {
            &-TotalText {
                dd:last-child {
                    font-weight: 600;
                }
            }
        }
    }

    &-GiftOptionsWrapper {
        width: 100%;
        border: none;
        inset-block-end: 0;
        background-color: $white;
        z-index: 100;
        padding-block: 16px;

        @include desktop {
            padding-block: 24px;
        }

        @include desktop {
            padding-inline-end: 60px;
            padding-inline-start: 60px;
        }

        @include mobile {
            padding: 16px;
        }

        @include tablet {
            padding-inline: 42px;
        }

        &_isCartEmpty {
            position: absolute;
            background-color: $gray-background-color;
            max-height: 70vh;
            overflow-y: scroll;

            @include desktop {
                padding-block: 16px;
            }

            @include mobile {
                max-height: 60svh;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
        }
    }

    &-GiftOptionsHeaderWrapper {
        margin-block-end: 16px;
    }

    &-GiftOptionsHeaderContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0,0,0,0.4);
        padding-block-end: 13px;
        cursor: pointer;
    }

    &-GiftOptionsHeader {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.1025rem;
        padding-block-end: 5px;
        margin: 0;
        letter-spacing: 0.08em;
    }

    &-UpArrow,
    &-DownArrow {
        border: solid $default-primary-base-color;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;

        &:hover {
            cursor: pointer;
        }
    }

    &-DownArrow {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    &-UpArrow {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    &-EmptySignIn {
        inset-block-start: unset;
    }

    &-GiftsSignInWrapper {
        margin-inline-end: 0;
        justify-self: center;

        .CartOverlay-SignIn {
            margin-inline-end: 0;
        }
    }

    &-SignInButton {
        font-size: 0.875rem;
        line-height: 1.125rem;
        font-weight: 600;
        height: auto;
        padding: 9px 23px;
        flex-basis: 80%;

        &:hover {
            border-color: transparent;
            color: $white;
            background-color: $default-primary-base-color;
        }
    }

    &-GiftItemsCount {
        font-weight: bold;
        letter-spacing: 0.08em;
    }

    &-GiftOptionsCount {
        opacity: 0.5;
        letter-spacing: 0.08em;
    }

    &-HeaderWrapper {
        @include tablet {
            padding-inline: 42px;
            padding-block-start: 24px;
        }
    }

    &-Header {
        @include tablet {
            text-align: start;
        }
    }

    &-ItemsWrapper {
        overflow-y: visible;
        margin-block-end: 0px;
        padding-block-end: 0px;

        @include after-mobile {
            padding-inline-start: 60px;
        }
    }

    &-MenuItem {
        .MenuOverlay-Close {
            @include after-mobile {
                inset-inline-start: 24px;
                inset-block-start: 24px;
            }
        }
    }

    &-Wrapper {
        overflow: auto;

        .CartOverlay-ItemsWrapper {
            @include tablet {
                padding-inline: 42px;
            }
        }

        a {
            text-decoration: none;
            cursor: default;
        }

        &:not(:has(.CartOverlay-EmptyCart)) {
            overflow-y: scroll;
        }
    }

    &-MenuOverlay {
        .MenuOverlay {
            &-Container {
                display: flex;
                flex-direction: column;
                max-height: 100vh;
            }

            &-Close {
                @include tablet {
                    inset-inline-end: 42px;
                    inset-block-start: 24px;
                }
            }
        }

        .CartItem {
            @include tablet {
                padding-inline-end: 0;
            }
        }
    }

    &-Discount,
    &-DiscountText,
    &-TaxText,
    &-TotalText {
        margin-block-start: 0px;
        margin-block-end: 0.5rem;
    }

    &-Discount {
        background: var(--light-beige);
        padding-block: 0px;
        padding-inline: 0px;

        li {
            &::before {
                content: none;
            }

            &:last-child {
                margin-block-end: 0px;
            }
        }

        .CheckoutOrderSummary {
            &-SummaryItem {
                display: flex;
                justify-content: space-between;
            }

            &:first-child {
                padding-block-start: 0px;
            }
        }
    }

    &-EmptyCart {
        .Loader {
            margin-block-start: 3rem;
            overflow: visible;
            opacity: unset;
        }
    }

    &-OrderButton {
        width: 100%;

        &_isGoingToCheckoutNotAllowed {
            opacity: 0.25;
        }
    }
}
