/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CartItem {
    @include after-mobile {
        padding-inline-end: 60px;
    }

    @include tablet {
        padding-inline-end: 18px;
    }

    &-Heading {
        max-width: 100%;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.25rem;

        &:hover {
            cursor: pointer;
        }
    }

    &_isCheckout {
        padding-inline-end: 0px;
    }

    &-Free, &-GiftQty, &-Change {
        flex-basis: 100%;
        text-align: end;
    }

    &-Free {
        font-weight: 900;
    }

    &-Change {
        color: var(--primary-base-color);
        font-weight: 600;
        border-bottom: 1px solid transparent;

        &:hover {
            border-color: $black;
            color: $black;
            cursor: pointer;
        }
    }

    &-GiftInfo {
        display: flex;
        flex-flow: column;
        flex-basis: 100%;
        align-items: flex-end;
    }

    &-ProductActions {
        &_IsGift {
            flex-direction: row;
        }
    }

    &-ActionButton {
        .ProductWishlistButton {
            cursor: pointer;
            font-size: 0.875rem;
        }
    }

    &-Price {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        data {
            margin-inline-start: 16px;

            @include tablet {
                font-size: 1rem;
            }

            @include mobile {
                font-size: 1rem;
            }
        }

        &_isCartOverlay {
            data {
                margin-inline-start: 12px;

                @include tablet {
                    font-size: 1rem;
                }

                @include mobile {
                    font-size: 1rem;
                }
            }
        }
    }

    &-Link {
        text-decoration: none;
    }

    .ProductPrice-SubPrice {
        font-size: 1.125rem;
        color: $black;
        opacity: 0.5;
        font-weight: 400;
        margin-block-start: 0px;

        @include mobile {
            font-size: 1rem;
        }

        @include tablet {
            font-size: 1rem;
        }
    }
}
