/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.GiftWrapCartItem {
    &-Wrapper {
        margin-block-start: 6px;
        margin-block-end: 14px;

        p {
            font-size: 12px;
        }

        .Note {
            font-weight: 700;

            &-AllCart {
                font-style: italic;
            }

            &-Price {
                padding-inline-start: 3px;
            }

            &-Label {
                font-weight: 400;
                padding-inline-end: 3px;

                &::before {
                    content: "";
                    display: block;
                }
            }
        }
    }
}
