/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.CartCoupon {
    &_hasError {
        margin-block-end: 1.75rem;

        @include large-widescreen {
            margin-block-end: 2.75rem;
        }
    }

    .CheckmarkIcon {
        display: none;
    }

    .Form {
        justify-content: space-between;
    }

    &-Button {
        height: 2.375rem !important;
        width: fit-content !important;
        min-width: 5.75rem !important;
        padding-inline: 0.5rem !important;

        &_isRemove {
            background-color: var(--primary-base-color);
            color: $white;
            height: 2.375rem;

            &:not([disabled]):hover {
                height: 2.375rem;
            }
        }

        &_isHollow:not([disabled]):hover {
            //This height was added to fix the height of the button on hover
            height: 2.375rem;
        }

        &:hover {
            background-color: var(--primary-base-color);
            height: 2.375rem;
        }
    }

    .Field {
        &-InputWrapper {
            @include desktop {
                height: 2.375rem;
            }
        }
    }

    &-Input {
        .Field_type_text {
            .Field-InputWrapper {
                input {
                    &:focus {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &-ErrorMessage {
        color: var(--primary-error-color);
        font-family: $font-Klavika;
        font-size: 0.875rem;
    }
}
